import React, { useState } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import drug from "../images/drugplaceholderImg.png"
import { Tab } from "react-tabs"
import { Link, navigate } from "gatsby"
import algoliasearch from "algoliasearch/lite"
import SearchBox from "./../components/SearchBox"
import {
  InstantSearch,
  connectHits,
  connectSearchBox,
  connectStateResults,
} from "react-instantsearch-dom"
import { Hits } from "../components/DisplayHits"
import { globalHistory } from "@reach/router"
import Buttn from "../components/Button"
import Seo from "../components/seo"
import api from "../utils/api"
import useWindowSize from "../utils/useWindowSize"
import Modal from "../components/home/modal"

import { Alert, Form, Row, Col } from "react-bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import LoadingOverlay from "react-loading-overlay"
import OrderModal from "../components/OrderModal"

const SimilarProduct = ({ item }) => {
  const isBrowser = typeof window !== "undefined"
  const user = isBrowser ? JSON.parse(window.localStorage.getItem("user")) : {}

  const [distributor, setDistributor] = React.useState(item.distributor)
  const [wholesalers, setWholesalers] = React.useState(item.wholesalers)
  const [isOrderModalOpen, setIsOpenOrder] = useState(false)

  const openModal = e => {
    e.preventDefault()

    if (!user?.placeOfWork?.license) {
      navigate("/profile")
    } else {
      setIsOpenOrder(true)
    }
  }

  const closeModal = () => setIsOpenOrder(false)

  return (
    <div>
      <PCard>
        <OrderModal
          distributor={distributor}
          wholesalers={wholesalers}
          drugId={item.id}
          open={isOrderModalOpen}
          close={closeModal}
        />
        <div>
          <PLink
            to={`/product/${item.productTradeName
              .replace(/ /g, "+")
              .toLowerCase()}`}
          >
            <ProductInfo2>
              <div >
                <img
                  css={`
                    width: 3rem;
                    height: 3rem;
                  `}
                  src={drug}
                  alt={item.productTradeName}
                  style={{ margin: "auto" }}
                />
                <p style={{ marginTop: "0.5rem", fontWeight: "bold" }}>
                  {item.productTradeName.toUpperCase()}
                </p>
              </div>
              <div>
                <p style={{ marginBottom: "0.5rem" }}>{item.innOfApi}</p>
                <p style={{ marginBottom: "0.5rem" }}>
                  {item.apiStrengthPerDosage}
                </p>
                <p style={{ marginBottom: "0.5rem" }}>{item.dosageFormName}</p>
                {/* <p style={{ marginBottom: "0.5rem" }}>
                  {item.distributor.distributor.name}
                </p> */}
              </div>
            </ProductInfo2>
          </PLink>
        </div>
        {/* <ButtonDiv>
          <ButtonGreenCard onClick={e => openModal(e)}>Order</ButtonGreenCard>

          <ButtonsCard>
            {Buttn(
              "Show Contact",
              distributor.distributor.handler?.phoneNumber
            )}
          </ButtonsCard>
        </ButtonDiv> */}
      </PCard>
    </div>
  )
}

const SimilarProducts = ({ products }) => {
  const productArray = products.map(item => item.node)

  return productArray.map((item, index) => (
    <SimilarProduct key={index} item={item} />
  ))
}

function RequestCallbackForm({ user, distributor }) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [callBackName, setCallBackName] = useState(user?.fullName || "")
  const [callBackNumber, setCallBackNumber] = useState("")

  const requestCallback = async e => {
    e.preventDefault()
    setSuccess("")
    setError("")

    const data = {
      userId: user.id,
      distributor,
      name: callBackName,
      number: callBackNumber,
    }

    try {
      if (!callBackName || !callBackNumber) {
        const message = "All fields are required"
        return setError(message)
      }
      if (callBackName.length < 3) {
        const message = "Name has to be more than two characters"
        return setError(message)
      }
      if (callBackNumber.length != 10) {
        const message = "Invalid phone number"
        return setError(message)
      }
      setError("")
      setLoading(true)
      await api().post(`/users/callback`, { ...data })
      setSuccess("Success, the distributor will call you back")
      setTimeout(() => {
        setSuccess("")
      }, 5000)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      const message = error.response.data.message
      setError(message)
    }
  }

  return (
    <div>
      <Form className="mx-2">
        <Form.Group>
          <Form.Label>
            <h5 className="text-muted pt-2">Request call back</h5>
          </Form.Label>
          {error ? <Alert variant="danger">{error}</Alert> : ""}
          {success ? <Alert variant="success">{success}</Alert> : ""}
          <LoadingOverlay active={loading} spinner text="">
            <Form.Control
              className="mb-2"
              type="text"
              value={callBackName}
              onChange={e => setCallBackName(e.target.value)}
            />
            <Form.Control
              type="text"
              placeholder="Your phone number"
              value={callBackNumber}
              onChange={e => setCallBackNumber(e.target.value)}
            />

            <RequestCallBackBtn onClick={requestCallback}>
              <div onClick={e => requestCallback}>Send</div>
            </RequestCallBackBtn>
          </LoadingOverlay>
        </Form.Group>
      </Form>
    </div>
  )
}

function SendMessageForm({ distributor, user }) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [message, setMessage] = useState("")
  const [email, setEmail] = useState(user?.email || "")

  const sendMessage = async e => {
    e.preventDefault()
    setSuccess("")
    setError("")

    const data = {
      userId: user.id,
      distributor,
      message,
    }

    try {
      if (!message) {
        const errorMessage = "Message is required"
        return setError(errorMessage)
      }
      if (message.length < 5) {
        const errorMessage = "Message has to be at least five characters"
        return setError(errorMessage)
      }

      setError("")
      setLoading(true)
      await api().post(`/users/message`, { ...data })
      setSuccess("Sent message to distributor")
      setTimeout(() => {
        setSuccess("")
      }, 5000)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      const message = error.response.data.message
      setError(message)
    }
  }

  return (
    <div>
      <Form className="mx-2">
        <Form.Group>
          {error ? (
            <Alert className="mt-1" variant="danger">
              {error}
            </Alert>
          ) : (
            ""
          )}
          {success ? (
            <Alert className="mt-1" variant="success">
              {success}
            </Alert>
          ) : (
            ""
          )}
          <LoadingOverlay active={loading} spinner text="">
            <Form.Control
              className="my-2"
              type="text"
              placeholder="Your email"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
            <Form.Control
              as="textarea"
              placeholder="Write your message here"
              rows={3}
              value={message}
              onChange={e => setMessage(e.target.value)}
            />

            <RequestCallBackBtn onClick={sendMessage}>
              <div>Send Message</div>
            </RequestCallBackBtn>
          </LoadingOverlay>
        </Form.Group>
      </Form>
    </div>
  )
}

function UploadFile({ user, distributorId }) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")

  const [selectedFile, setSelectedFile] = useState({})
  const [isFilePicked, setIsFilePicked] = useState(false)
  const [fileUrl, setFileUrl] = useState("")

  const handleChangeFile = event => {
    setSelectedFile(event.target.files[0])
    setFileUrl(URL.createObjectURL(event.target.files[0]))
    setIsFilePicked(true)
  }

  const uploadFile = async () => {
    try {
      const formData = new FormData()

      formData.append("file", selectedFile)
      formData.append("distributorId", distributorId)
      formData.append("userId", user.id)

      setLoading(true)
      await api().post(`/uploads`, formData)
      setLoading(false)
      setSuccess("Uploaded file")

      setSelectedFile({})
      setIsFilePicked(false)
      setFileUrl("")
    } catch (err) {
      const { message } = err.response.data
      setLoading(false)
      setError(message)
    }
  }

  return (
    <div>
      <Form className="mx-2">
        <Form.Group>
          <Form.Label>
            <h5 className="text-muted pt-2">Upload stock order list for quotation</h5>
          </Form.Label>
          {error ? <Alert variant="danger">{error}</Alert> : ""}
          {success ? <Alert variant="success">{success}</Alert> : ""}
          <LoadingOverlay active={loading} spinner text="">
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Control
                type="file"
                onChange={e => {
                  handleChangeFile(e)
                }}
              />
            </Form.Group>

            <RequestCallBackBtn
              onClick={e => {
                uploadFile()
              }}
            >
              Upload
            </RequestCallBackBtn>
          </LoadingOverlay>
        </Form.Group>
      </Form>
    </div>
  )
}

const Distributor = ({ location, pageContext, data }) => {
  const { width } = useWindowSize()

  const distributor = {
    ...pageContext.distributor,
  }

  const distributorProducts = data.distributorProducts?.edges

  const isBrowser = typeof window !== "undefined"
  const user = isBrowser ? JSON.parse(window.localStorage.getItem("user")) : {}

  const [searchedState, setsearchedState] = useState(
    location.pathname.split("/").slice(2).toString().replace(/\+/g, " ")
  )

  const searchClient = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID,
    process.env.GATSBY_ALGOLIA_ADMIN_KEY
  )

  const CustomHits = connectHits(Hits)
  const CustomSearchBox = connectSearchBox(SearchBox)
  let urlQuery = globalHistory.location.search
    ? globalHistory.location.search.replace("?query=", "")
    : globalHistory.location.pathname
      .split("/")
      .slice(2)
      .toString()
      .replace(/\+/g, " ")

  const onSearchStateChange = state => {
    if (state.RefinementList?.productTradeName) {
      setsearchedState(state.RefinementList.productTradeName)
    }
  }

  const Results = connectStateResults(({ searchState }) =>
    searchState && searchState.query ? (
      <div>
        <CustomHits />
      </div>
    ) : (
      <div></div>
    )
  )

  const keyword = distributor.name
    ? distributor.name.toLowerCase() + " products in Kenya | Patameds for retailers"
    : "Pharmaceutical company Products in Kenya | Patameds"
  const keyword2 = distributor.name
    ? distributor.name.toLowerCase() +
    " products in Kenya - price, brand names, uses, "
    : "Pharmaceutical company Products in Kenya - price, brand names, uses, "
  const keyword3 = distributor.name
    ? distributor.name.toLowerCase() + " products in Kenya, "
    : "Pharmaceutical company Products in Kenya, "
  const keyword4 = distributor.name
    ? distributor.name.toLowerCase() + " in Kenya - price, brand names, uses"
    : "Pharmaceuticlal companies in Kenya - price, brand names, uses"
  const keyword_desc = distributor.name
    ? distributor.name.toLowerCase() +
    " Browse distributor products, send message, request med rep to call back, upload stock orders"
    : "Pharmaceutical company Products Browse distributor products, send message, request call back, upload stock orders"
  const siteurl = "https://www.patameds.com/distributor/" + distributor.name.replace(/ /g, "+").toLowerCase()

  const [
    filteredDistributorProducts,
    setFilteredDistributorProducts,
  ] = useState(distributorProducts)

  const [searchQuery, setSearchQuery] = useState("")

  React.useEffect(() => {
    if (searchQuery) {
      const data = distributorProducts.filter(
        item =>
          item.node.productTradeName.includes(searchQuery.toLowerCase()) ||
          item.node.innOfApi.includes(searchQuery.toLowerCase())
      )
      setFilteredDistributorProducts(data)
    }
  }, [searchQuery])

  return (
    <Layout>
      <Modal />
      <Seo
        title={keyword}
        description={keyword_desc}
        keywords={keyword2 + keyword3 + keyword4}
        img="https://www.patameds.com/static/logo-da9b52b652992ee5cb44de5673062d5e.svg"
        siteUrl={siteurl}
        canonical={siteurl}
      ></Seo>
      <Container>
        <InstantSearch
          searchClient={searchClient}
          indexName={process.env.GATSBY_ALGOLIA_INDEX_NAME}
          onSearchStateChange={onSearchStateChange}
        >
          {/* <SearchHit>
            <CustomSearchBox defaultRefinement={urlQuery} />
            <Results />
          </SearchHit> */}

          <ProductDetails>
            <ProductScreenshot>
              <TabsStyle>
                <TabPanelStyle>
                  <Tab>
                    <img src={drug} alt="drug" />
                  </Tab>
                </TabPanelStyle>
              </TabsStyle>

              <OwnerDetails>
                <OwnerInfo>
                  <OwnerLogo>{distributor.name.charAt(0)}</OwnerLogo>
                  <OwnerDistributor>{distributor.name}</OwnerDistributor>
                  {/* <OwnerHandler>Dr. {`${distributor.handler.firstName} ${distributor.handler.lastName}`}</OwnerHandler> */}
                  <OwnerHandler>Products registered under {`${distributor.name}`}</OwnerHandler>
                </OwnerInfo>

                {/* <ButtonsOwner>
                  <ButtonOwner
                    css={`
                      padding: 0.5rem 1rem;
                    `}
                  >
                    {Buttn("Show Contact", distributor.handler.phoneNumber)}
                  </ButtonOwner>
                  <br />
                </ButtonsOwner> */}

                <CallBackDetails>
                  <SendMessageForm distributor={distributor} user={user} />
                </CallBackDetails>

                <CallBackDetails>
                  <RequestCallbackForm user={user} distributor={distributor} />
                </CallBackDetails>

                <CallBackDetails>
                  <UploadFile user={user} distributorId={distributor.id} />
                </CallBackDetails>
              </OwnerDetails>
            </ProductScreenshot>

            <Products>
              <ProductHeader>Distributor Products</ProductHeader>
              <ProductCards>
                {distributorProducts.length > 10 && (
                  <Row>
                    <Col></Col>
                    <Col xs={9} md={6}>
                      <Form.Control
                        className="my-2"
                        type="text"
                        placeholder="Search distributor products"
                        value={searchQuery}
                        onChange={e => setSearchQuery(e.target.value)}
                      />
                    </Col>
                    <Col></Col>
                  </Row>
                )}

                <SimilarProducts products={filteredDistributorProducts} />
              </ProductCards>
            </Products>
          </ProductDetails>
        </InstantSearch>
      </Container>
    </Layout>
  )
}

export default Distributor

export const query = graphql`
  query($distributorId: String!) {
    distributorProducts: allDrugs(
      filter: { distributor: { distributor: { id: { eq: $distributorId } } } }
    ) {
      edges {
        node {
          id
          apiStrengthPerDosage
          atcCodeName
          dosageFormName
          innOfApi
          mahCompanyAddress
          mahCompanyEmail
          mahCompanyName
          pharmacotherapeuticGroup
          productTradeName
          productVisualDesc
          retentionNo
          retentionYear
          routesName
          shelfLifeName
          siteAddress
          siteName
          type
          category
          distributor {
            distributor {
              id
              name
              handler {
                email
                firstName
                lastName
                phoneNumber
              }
            }
            price {
              previous
              current
              vat
              discount
            }
            packSize
            minQuantity
            showPrice
          }
          wholesalers {
            wholesaler {
              id
              name
              locations
              handler {
                email
                firstName
                lastName
                phoneNumber
              }
            }
            price {
              previous
              current
              vat
              discount
            }
            minQuantity
            packSize
            showPrice
            showWholesaler
          }
        }
      }
    }
  }
`

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #e4e4e4;
`
const ProductDetails = styled.div`
  margin-top: 0px;
`
const ProductScreenshot = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  li {
    list-style: none;
  }

  @media screen and (min-width: 900px) {
    flex-direction: row;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
`
const TabsStyle = styled.div`
  height: 100%;
  background: #fff;
  margin: 0rem 1rem;
  margin-bottom: 1rem;

  @media screen and (min-width: 900px) {
    width: 60%;
  }
`
const TabPanelStyle = styled.div`
  display: flex;
  justify-content: center;
  width: 80%;
  margin: auto;

  img {
    width: 100%;
    height: 100%;
  }
`
const OwnerDetails = styled.div`
  height: 100%;
  background: #fff;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;

  @media screen and (min-width: 700px) {
    width: 50%;
  }

  @media screen and (min-width: 900px) {
    width: 35%;
  }

  @media screen and (min-width: 1100px) {
    width: 30%;
  }
`
const OwnerDistributor = styled.h5`
  text-align: center;
  width: 80%;
  margin-top: 1rem;
`
const OwnerHandler = styled.p`
  border: 2px solid #03989e;
  padding: 1rem 2rem;
  margin: 1rem 2rem;
  border-radius: 1rem;
  text-align: center;
`
const OwnerInfo = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`
const CallBackDetails = styled.div`
  border-top: 10px solid #e4e4e4;
`
const OwnerLogo = styled.div`
  margin-top: 1rem;
  border-radius: 50%;
  background: #03989e;
  height: 100px;
  width: 100px;
  font-size: 4rem;
  color: #fff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`
const ProductInfo2 = styled.div`
  display: flex;
  text-align: center;
  margin: 0.1rem;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction:column;
  color: #000;

  @media screen and (min-width: 400px) {
    justify-content: space-between;
    flex-direction:row;
    width: 100%;

    div {
      width: 50%;
      // flex-wrap: wrap;
      // justify-content: center;
    }
  }
`
const Products = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #e4e4e4;
  width: 100%;
`

const ProductHeader = styled.h1`
  text-align: center;
  font-size: clamp(1.3rem, 3vw, 2rem);
  margin-top: 1rem;
`

const ProductCards = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0rem 0.5rem;
  //align-items: flex-start;

  @media screen and (min-width: 700px) {
    // align-items: center;
    padding: 0rem 5rem;
  }
`
const PCard = styled.div`
  width: 80%;
  border: 3px solid #03989e;
  border-radius: 10px;
  height: 100%;
  background: white;
  margin: 0.5rem 0rem;
  padding: 0.5rem 0.5rem;
  margin-left: auto;
  margin-right: auto;

  @media screen and (min-width: 600px) {
    width: 100%;
  }
  @media screen and (min-width: 1100px) {
    width: 70%;
  }
`

const PLink = styled(Link)`
  :hover {
    text-decoration: none;
  }

  // img {
  //   width: 70%;
  //   height: 70%;
  // }

  @media screen and (min-width: 600px) {
    flex-direction: row;
    justify-content: space-around;
  }
`

const ButtonsOwner = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 1rem;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
`
const RequestCallBackBtn = styled.div`
  background: #03989e;
  padding: 1rem 4rem;
  margin-top: 1rem;
  color: #fff;
  text-align: center;
  cursor: pointer;
`

const ButtonOwner = styled.div`
  background: #03989e;
  padding: 1rem 2rem;
  margin-top: 1rem;
  border-radius: 3rem;
  color: #fff;
  text-align: center;
  cursor: pointer;
  font-size: 1rem;
`
const SearchHit = styled.div`
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-self: center;
  position: sticky;
  top: 60px;
`
const ButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`
const ButtonsCard = styled.div`
  width: 50%;
  height: 35px;
  margin-left: 1px;
`
const ButtonGreenCard = styled.div`
  background: #03989e;
  border-radius: 7.5%;
  color: #fff;
  text-align: center;
  width: 50%;
  height: 35px;
  padding-top: 0.1rem;
  cursor: pointer;
`
