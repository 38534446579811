import React from "react"

export default function useWindowSize() {
  const [windowSize, setWindowSize] = React.useState({
    width: 1200,
    height: 800,
  })

  function changeWindowSize() {
    setWindowSize({ width: window.innerWidth, height: window.innerHeight })
  }

  React.useEffect(() => {
    window.addEventListener("resize", changeWindowSize)
    changeWindowSize()

    return () => {
      window.removeEventListener("resize", changeWindowSize)
    }
  }, [])

  return windowSize
}
